// src/redux/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  userProfile: null,
  userPassword: null,
  userName: null,
};

// Create slice
/* This code block is creating a slice for managing user-related state in a Redux store using the
`createSlice` function from `@reduxjs/toolkit`. */
const userSlice = createSlice({
  name: "user",
  initialState,
  /* This code block defines the reducers for the `userSlice` slice in Redux. */
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload.profile;
      state.userPassword = action.payload.password;
      state.userName = action.payload.username;
    },
    clearUserProfile: (state) => {
      state.userProfile = null;
      state.userPassword = null;
      state.userName = null;
    },
  },
});

// Export actions
/* `export const { setUserProfile, clearUserProfile } = userSlice.actions;` is exporting the
`setUserProfile` and `clearUserProfile` actions from the `userSlice` slice. This allows other parts
of the application to import and use these actions to interact with the Redux store managed by the
`userSlice` reducer. */
export const { setUserProfile, clearUserProfile } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
