import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Emailverification from "./pages/Emailverification";
import OtpVerification from "./pages/Otpverification";
import SetPassword from "./pages/Setpassword";
import Winner from "./pages/Winner";
import Wallet from "./pages/Wallet";
import Paypalsuccess from "./pages/Paypalsuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import UpdatePassword from "./pages/Updatepassword";
import Termsandconditions from "./pages/Termsandconditions";
import Editprofile from "./pages/Editprofile";
import Editpassword from "./pages/Editpassword";
import Deleteaccount from "./pages/Deleteaccount";
import Contactus from "./pages/Contactus";
import History from "./pages/History";
import endpoint from "./Endpointurl";
import Paypalcancel from "./pages/Paypalcancel";
import PrivateRoute from "./pages/PrivateRoute";
import PublicRoute from "./pages/PublicRoute";
import url from "./url";
import { io } from "socket.io-client";
import NewDashboard from "./pages/NewDashboard";
import SocketHandler from "./pages/SocketHandler";

// Current app version
/* The `CURRENT_APP_VERSION` constant in the code snippet is used to store the current version of the
application. It is set to "5.0.0" in this case. The comment next to it suggests that this value
should be updated whenever significant changes are made to the application. */
const CURRENT_APP_VERSION = "5.0.0"; // Update this when making significant changes

function App() {
  const navigate = useNavigate();

  // Function to check app version
  const checkAppVersion = () => {
    const storedVersion = localStorage.getItem("appVersion");

    if (!storedVersion) {
      // First time user or cleared localStorage
      localStorage.clear();
      localStorage.setItem("appVersion", CURRENT_APP_VERSION);
    } else if (storedVersion !== CURRENT_APP_VERSION) {
      // Version mismatch - app has been updated
      alert("A new version of the application is available. Please login again to continue.");
      // Clear ALL localStorage items
      localStorage.clear();
      // Set the new version after clearing
      localStorage.setItem("appVersion", CURRENT_APP_VERSION);
      // Redirect to login
      navigate(`${endpoint}`);
    }
    // If versions match, do nothing
  };

  useEffect(() => {
    // Check app version on component mount
    checkAppVersion();
    // localStorage.clear()
  }, []);

  return (
    <>
      <SocketHandler />
      <Routes>
        <Route
          path={`${endpoint}`}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}signup`}
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}emailverification`}
          element={
            <PublicRoute>
              <Emailverification />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}otpverification`}
          element={<OtpVerification />}
        />
        <Route path={`${endpoint}setpassword`} element={<SetPassword />} />


        <Route
          path={`${endpoint}dashboard`}
          element={
            <PrivateRoute>
              <NewDashboard />
            </PrivateRoute>
          }
        />


        <Route
          path={`${endpoint}winner`}
          element={
            <PrivateRoute>
              <Winner />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}wallet`}
          element={
            <PrivateRoute>
              <Wallet />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}success`}
          element={
            <PrivateRoute>
              <Paypalsuccess />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}privacypolicy`}
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}termsconditions`}
          element={
            <PrivateRoute>
              <TermsConditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}updatepassword`}
          element={
            <PublicRoute>
              <UpdatePassword />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}termsandconditions`}
          element={
            <PrivateRoute>
              <Termsandconditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}history`}
          element={
            <PrivateRoute>
              <History />
            </PrivateRoute>
          }
        />

        <Route
          path={`${endpoint}editprofile`}
          element={
            <PrivateRoute>
              <Editprofile />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}changepassword`}
          element={
            <PrivateRoute>
              <Editpassword />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}deleteaccount`}
          element={
            <PrivateRoute>
              <Deleteaccount />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}contactus`}
          element={
            <PrivateRoute>
              <Contactus />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}cancel`}
          element={
            <PrivateRoute>
              <Paypalcancel />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
