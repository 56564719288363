// src/redux/persist.js
/**
 * The function `loadState` retrieves and parses a serialized state from localStorage, returning the
 * parsed state or undefined if an error occurs.
 * @returns If there is a saved state in the localStorage with the key 'reduxState', it will be
 * returned after parsing it from JSON format. If there is no saved state, undefined will be returned.
 * If there is an error during the process, an error message will be logged to the console and
 * undefined will be returned.
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined; // Use initial state if no saved state exists
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Error loading state from localStorage:", err);
    return undefined;
  }
};

/**
 * The `saveState` function serializes the state object to JSON and saves it to the browser's
 * localStorage.
 */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Error saving state to localStorage:", err);
  }
};
